export default function Footer() {
  return (
    <>
      <footer className="flex justify-center gap-2 prose-sm">
        <span>
          &copy; {new Date().getFullYear()} <i className="fa fa-heart"></i>{" "}
        </span>
        {" "}|{" "}
        <a href={"/impressum"}>Impressum</a>
        {" "}|{" "}
        <a href={"/datenschutzerklaerung"}>Datenschutzerklärung</a>
      </footer>
    </>
  );
}
